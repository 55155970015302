import { useState } from 'preact/hooks'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { multiSort } from 'Helpers/utils'
import { useSlice } from 'State'
import useFeatures from 'Hooks/useFeatures'
import TransitionWrapper from 'Components/TransitionWrapper'
import Layout from 'Components/Layout'
import Source from 'Components/Source'
import PageHeader from 'Components/PageHeader'
import Pagination from 'Components/Pagination'
import PlusIcon from 'Images/icons/plus.svg'
import ConnectIcon from 'Images/icons/connect.svg'
import CaretDown from 'Images/icons/caret-down.svg'
import styles from './Sources.module.scss'

export default function Sources() {
  const { user, authorizedAccounts, plans, feeds } = useSlice(
    'user',
    'authorizedAccounts',
    'plans',
    'feeds',
  )
  const features = useFeatures()
  const [sortBy, setSortBy] = useState('label')
  const [sortOrder, setSortOrder] = useState('asc')
  const [currentPage, setCurrentPage] = useState(1)
  let pageSize = 12

  if (Object.values(feeds).length > 240) {
    pageSize = 25
  }

  if (Object.values(feeds).length > 500) {
    pageSize = 50
  }

  /**
   * Set sort order and value
   */
  function setSort(by, order) {
    if (order === 'toggle') {
      order = sortOrder === 'desc' ? 'asc' : 'desc'
    }
    if (by === sortBy) {
      setSortOrder(order)
    }
    setSortBy(by)
  }

  /*
   *   Active sources
   */
  const activeAccounts =
    authorizedAccounts &&
    Object.values(authorizedAccounts).filter((a) => a.active)

  /*
   *   Active Plan
   */
  const planId = user?.plan
  const activePlan = planId ? plans?.[planId] : null

  /*
   *   Allowed to activate more accounts?
   */
  const allowedToActivateAccounts =
    activePlan?.maxSources === -1 ||
    activeAccounts?.length < activePlan?.maxSources

  const showCompactView = features.has('api')

  let sortedSources = authorizedAccounts
    ? Object.values(authorizedAccounts)
        .filter((source) => source.label)
        .map((s) => {
          s.isExpired = 0

          if (
            s.accountType === 'FACEBOOK' &&
            s.expiresOn.toMillis() - Date.now() < 0
          ) {
            s.isExpired = 1
          }
          return s
        })
    : []

  switch (sortBy) {
    case 'label':
      sortedSources = multiSort(
        sortedSources,
        ['label', 'type', 'isExpired'],
        sortOrder,
      )
      break
    case 'type':
      sortedSources = multiSort(
        sortedSources,
        ['type', 'label', 'isExpired'],
        sortOrder,
      )
      break
    case 'isExpired':
      sortedSources = multiSort(
        sortedSources,
        ['isExpired', 'label', 'type'],
        sortOrder,
      )
      break
  }

  sortedSources = sortedSources.sort((a, b) => {
    if (a.type?.includes('Legacy')) return 1
    if (b.type?.includes('Legacy')) return -1
    return 0
  })

  /*
   *   Sources
   */
  const sourceEls = sortedSources?.length
    ? sortedSources
        .filter((_f, i) => {
          if (!showCompactView) return true
          return (
            i + 1 >= currentPage * pageSize - pageSize + 1 &&
            i + 1 <= currentPage * pageSize
          )
        })
        .map((s, i, array) => {
          return (
            <Source
              key={s.businessId ?? s.instagramUserId ?? s.facebookUserId}
              plan={activePlan}
              account={s}
              allowedToActivate={allowedToActivateAccounts}
              first={i === 0}
              last={i === array.length - 1}
              isCompact={showCompactView}
            />
          )
        })
    : null

  const addAFeedMessage =
    authorizedAccounts &&
    Object.keys(authorizedAccounts).length &&
    (!feeds || !Object.keys(feeds).length) ? (
      <TransitionWrapper className={styles.no_feeds_message}>
        Nice! You&apos;ve connected a source. Now let&apos;s{' '}
        <Link to="/add-feed" className={styles.no_feeds_link}>
          Add a feed
        </Link>
      </TransitionWrapper>
    ) : null

  const accountsWrapperEl = feeds ? (
    <div
      className={classnames(styles.sources, {
        [styles.compact]: showCompactView,
      })}
    >
      {sourceEls}
    </div>
  ) : (
    <TransitionWrapper className={styles.sources_loading}>
      <div className={styles.sources_loading__loader}></div>
    </TransitionWrapper>
  )

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Sources | Behold">
      <main className={classnames(styles.container)}>
        <PageHeader
          title="Sources"
          buttonText={
            <>
              <PlusIcon /> Add source
            </>
          }
          buttonTo={'/add-source'}
        />
        {sourceEls && (
          <div className={styles.column_titles}>
            <button
              className={styles.column_title}
              onClick={() => setSort('label', 'toggle')}
            >
              Name{' '}
              <div
                className={classnames(styles.column_title__caret, {
                  [styles.current]: sortBy === 'label',
                  [styles.sort_asc]: sortOrder === 'asc',
                })}
              >
                <CaretDown />
              </div>
            </button>
            <button
              className={classnames(styles.column_title, styles.type)}
              onClick={() => setSort('type', 'toggle')}
            >
              Type{' '}
              <div
                className={classnames(styles.column_title__caret, {
                  [styles.current]: sortBy === 'type',
                  [styles.sort_asc]: sortOrder === 'asc',
                })}
              >
                <CaretDown />
              </div>
            </button>
            <button
              className={styles.column_title}
              onClick={() => setSort('isExpired', 'toggle')}
            >
              Expires{' '}
              <div
                className={classnames(styles.column_title__caret, {
                  [styles.current]: sortBy === 'isExpired',
                  [styles.sort_asc]: sortOrder === 'asc',
                })}
              >
                <CaretDown />
              </div>
            </button>
          </div>
        )}
        {accountsWrapperEl}

        {showCompactView && (
          <Pagination
            items={sortedSources}
            pageSize={pageSize}
            curr={currentPage}
            onChange={setCurrentPage}
          />
        )}

        {(!authorizedAccounts || !Object.values(authorizedAccounts).length) &&
          !!feeds && (
            <Link to="/add-source" className={styles.empty_message}>
              <div className={styles.empty_message__inner}>
                <ConnectIcon />
                <div>
                  Welcome! To get started let&apos;s <u>connect a source</u>
                </div>
              </div>
            </Link>
          )}
        {authorizedAccounts &&
          Object.values(authorizedAccounts).length &&
          feeds &&
          !showCompactView && (
            <TransitionWrapper>
              <Link to="/add-source" className={styles.add_source}>
                <div className={styles.add_source__inner}>
                  <PlusIcon /> Add source
                </div>
              </Link>
            </TransitionWrapper>
          )}

        {addAFeedMessage}
      </main>
    </Layout>
  )
}
