import classNames from 'classnames'
import styles from './Pagination.module.scss'

export default function Pagination({ items, pageSize, curr, onChange }) {
  const numPages = Math.ceil(items.length / pageSize)
  let pages = []
  let pageButtonEls = []

  function PageButton({ page }) {
    return (
      <button
        key={page.toString()}
        className={classNames(styles.page, { [styles.current]: curr === page })}
        onClick={() => onChange(page)}
      >
        {page}
      </button>
    )
  }

  if (numPages < 10) {
    for (let i = 1; i <= numPages; i++) {
      pages.push(i)
    }
    pageButtonEls = pages.map((p) => <PageButton page={p} />)
  } else {
    if (curr < 6) pages = [1, curr - 1, curr, curr + 1, numPages]

    if (curr < 5) {
      pages = [1, 2, 3, 4, 5, 'sep', numPages]
    } else if (curr < numPages - 3) {
      pages = [1, 'sep', curr - 1, curr, curr + 1, 'sep', numPages]
    } else {
      pages = [
        1,
        'sep',
        numPages - 4,
        numPages - 3,
        numPages - 2,
        numPages - 1,
        numPages,
      ]
    }

    pageButtonEls = pages.map((p) => {
      return p === 'sep' ? (
        <div className={styles.separator}>&hellip;</div>
      ) : (
        <PageButton page={p} />
      )
    })
  }

  const firstItem = pageSize * curr - pageSize + 1
  const lastItem = pageSize * curr

  return pageButtonEls.length > 1 ? (
    <menu className={styles.container}>
      <div className={styles.pagination}>
        Showing {firstItem.toLocaleString()} -{' '}
        {Math.min(lastItem, items.length).toLocaleString()} of{' '}
        {items.length?.toLocaleString()}
      </div>
      <div className={styles.buttons}>{pageButtonEls}</div>
    </menu>
  ) : null
}
