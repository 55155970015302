import PropTypes from 'prop-types'
import HelpTooltip from 'Components/HelpTooltip'
import styles from './PlanDetails.module.scss'

export default function PlanDetails({ planId, planObj }) {
  const surgeTooltip = (
    <HelpTooltip
      width={280}
      triggerText={
        <>
          <strong>✓</strong> Surge protection
        </>
      }
    >
      <div className={styles.tooltip_text}>
        <h1>Surge protection</h1>
        With our paid plans you&apos;re protected if you get a traffic spike, as
        long as you don&apos;t hit your limit two months in a row.
      </div>
    </HelpTooltip>
  )

  const details = {
    Free: [
      <>
        <strong>1,200</strong> views / mo
      </>,
      <>
        <strong>1</strong>{' '}
        <HelpTooltip width={325} triggerText="hashtag per feed ">
          <div className={styles.tooltip_text}>
            <h1>Hashtags per feed</h1>
            With our Graph API integration you can create hashtag feeds that
            fetch public posts. On paid plans you can mix more than one hashtag
            in a single feed.
          </div>
        </HelpTooltip>
      </>,
      <>
        Updates <strong>daily</strong>
      </>,
      <>
        <strong>6</strong> most recent posts
      </>,
      <>
        <strong className={styles.x}>×</strong>{' '}
        <HelpTooltip
          width={300}
          triggerText={<span className={styles.red}>No surge protection</span>}
        >
          <div className={styles.tooltip_text}>
            <h1>No surge protection</h1>
            On our free plan your account will be paused whenever you hit your
            limit of views. It will reactivate at the start of the next month or
            when you upgrade.
          </div>
        </HelpTooltip>
      </>,
      <>
        <strong className={styles.x}>×</strong>{' '}
        <span className={styles.red}>No Klaviyo widgets</span>
      </>,
    ],
    Personal: [
      <>
        <strong>15,000</strong> views / mo
      </>,
      <>
        <strong>3</strong>{' '}
        <HelpTooltip width={325} triggerText="hashtags per feed">
          <div className={styles.tooltip_text}>
            <h1>Hashtags per feed</h1>
            With our Graph API integration you can create hashtag feeds that
            fetch public posts. On paid plans you can mix more than one hashtag
            in a single feed.
          </div>
        </HelpTooltip>
      </>,
      <>
        Updates <strong>every minute</strong>
      </>,
      <>
        <strong>50</strong> most recent posts
      </>,
      <>{surgeTooltip}</>,
      <>
        <strong className={styles.x}>×</strong>{' '}
        <span className={styles.red}>No Klaviyo widgets</span>
      </>,
    ],
    Business: [
      <>
        <strong>125,000</strong> views / mo
      </>,
      <>
        <strong>5</strong>{' '}
        <HelpTooltip width={325} triggerText="hashtags per feed">
          <div className={styles.tooltip_text}>
            <h1>Hashtags per feed</h1>
            With our Graph API integration you can create hashtag feeds that
            fetch public posts. On paid plans you can mix more than one hashtag
            in a single feed.
          </div>
        </HelpTooltip>
      </>,
      <>
        Updates <strong>every minute</strong>
      </>,
      <>
        <strong>100</strong> most recent posts
      </>,
      <>{surgeTooltip}</>,
      <>
        <strong>✓</strong> Klaviyo widgets
      </>,
    ],
    Enterprise: [
      <>
        <strong>2 million</strong> views / mo
      </>,
      <>
        <strong>10</strong>{' '}
        <HelpTooltip width={325} triggerText="hashtags per feed">
          <div className={styles.tooltip_text}>
            <h1>Hashtags per feed</h1>
            With our Graph API integration you can create hashtag feeds that
            fetch public posts. On paid plans you can mix more than one hashtag
            in a single feed.
          </div>
        </HelpTooltip>
      </>,
      <>
        Updates <strong>every minute</strong>
      </>,
      <>
        <strong>200</strong> most recent posts
      </>,
      <>{surgeTooltip}</>,
      <>
        <strong>✓</strong> Klaviyo widgets
      </>,
    ],
    platform: [
      <p>
        For platform developers who need access to the Behold API. Cost is
        usage-based after exceeding a monthly allowance of views and
        sources/feeds.
      </p>,
      <a href="https://behold.so/pricing#platform" target="_blank">
        See full pricing details
      </a>,
    ],
  }

  return planId && planObj ? (
    <div className={styles.details}>
      <h3 className={styles.details__title}>{planObj.title}</h3>
      <ul className={styles.details__list}>
        {details[planId]?.map((d, i) => (
          <li key={i}>{d}</li>
        ))}
      </ul>
    </div>
  ) : (
    <div className={styles.details_loader} />
  )
}

PlanDetails.propTypes = {
  planId: PropTypes.string,
  planObj: PropTypes.object,
}
