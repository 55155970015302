import { auth } from 'Fire/firebaseInit'
import { makeFetchUrl } from 'Helpers/utils'

/**
 *   @param {string} feedId
 *   @param {AbortSignal} signal
 */
export async function getFeed(feedId, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)

    const res = await fetch(
      makeFetchUrl(`${process.env.API_URL}/v1/feeds/${feedId}`),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        signal,
      },
    )

    const json = await res.json()

    if (!res.ok) {
      throw new Error(json.message)
    }

    return json.data
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

// @TODO migrate to API once hashtag feeds, klaviyo feeds and all widget types are supported

export async function createFeed(feedOptions, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)

    const res = await fetch(makeFetchUrl(`${process.env.API_URL}/v1/feeds`), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedOptions),
      signal,
    })

    const json = await res.json()

    if (!res.ok) {
      throw new Error(json.message)
    }

    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}
