import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { deleteFeed } from 'Fire/firebaseActions'
import { clearFeedCache } from 'Helpers/instaApi'
import { getFeed } from 'Helpers/api'
import { copyText } from 'Helpers/utils'
import useTimers from 'Hooks/useTimers'
import useFeatures from 'Hooks/useFeatures'
import { useSlice } from 'State'
import MenuItem from './MenuItem'
import SettingsIcon from 'Images/icons/settings.svg'
import EditIcon from 'Images/icons/pencil.svg'
import BroomIcon from 'Images/icons/broom.svg'
import TrashIcon from 'Images/icons/trash.svg'
import CopyIcon from 'Images/icons/copy-alt.svg'
import styles from './FeedMenu.module.scss'

export default function FeedMenu({
  showSettings = true,
  feedId,
  editTitleHandler,
}) {
  const appState = useSlice('feeds', 'authorizedAccounts')
  const feed = appState.feeds[feedId]
  const source = feed ? appState.authorizedAccounts?.[feed.sourceId] : null
  const features = useFeatures()
  const configButtonRef = useRef()
  const [configButtonIsLoading, setConfigButtonIsLoading] = useState(false)

  const copyFeedConfig = useCallback(async () => {
    setConfigButtonIsLoading(true)

    try {
      const res = await getFeed(feedId)

      const createFeedConfig = {
        title: res.title,
        sourceId: res.sourceId,
        ...(res.associatedAccountId
          ? { associatedAccountId: res.associatedAccountId }
          : {}),
        outputType: res.outputType,
        contentSource: res.contentSource,
        ...(res.hashtags ? { hashtags: res.hashtags } : {}),
        settings: res.settings,
        ...(res.widgetSettings ? { widgetSettings: res.widgetSettings } : {}),
      }
      const bbox = configButtonRef.current?.getBoundingClientRect()
      copyText(null, JSON.stringify(createFeedConfig, null, 2), {
        x: bbox.x + bbox.width / 2,
        y: bbox.y - 5,
        duration: 1000,
        forcePosition: 'top',
      })

      setConfigButtonIsLoading(false)
    } catch (error) {
      console.log(error)
      setConfigButtonIsLoading(false)
    }
  }, [feedId])

  const isUpdating =
    feed?.isUpdating &&
    (feed.contentSource === 'HASHTAG' ? feed.hashtags?.length : true)

  const isLegacySource = source?.type?.includes('Legacy')

  return (
    <>
      {showSettings && (
        <MenuItem to={`/feeds/${feedId}`}>
          <SettingsIcon /> Settings
        </MenuItem>
      )}
      {!isLegacySource && (
        <ClearCacheButton isUpdating={isUpdating} feedId={feedId} />
      )}
      {editTitleHandler && (
        <MenuItem onClick={editTitleHandler}>
          <EditIcon />
          Edit title
        </MenuItem>
      )}
      {features.has('api') && feed?.subtype !== 'KLAVIYO' && (
        <MenuItem
          ref={configButtonRef}
          onClick={copyFeedConfig}
          loading={configButtonIsLoading}
        >
          <CopyIcon /> Copy config
        </MenuItem>
      )}
      <hr />
      <MenuItem
        confirm
        confirmText={'Confirm Delete'}
        onClick={() => deleteFeed(feedId)}
        color="red"
      >
        <TrashIcon /> Delete Feed
      </MenuItem>
    </>
  )
}

FeedMenu.propTypes = {
  showSettings: PropTypes.bool,
  isUpdating: PropTypes.bool,
  feedId: PropTypes.string,
}

function ClearCacheButton({ feedId, isUpdating }) {
  const { st, cancelAllTimers } = useTimers()
  const [status, setStatus] = useState(null)
  const statusRef = useRef(status)

  function handleClick() {
    if (status) return
    setStatus('LOADING')
    clearFeedCache(feedId)
  }

  useEffect(() => {
    if (isUpdating && statusRef.current !== 'LOADING') {
      statusRef.current = 'LOADING'
      setStatus('LOADING')
    }
    if (!isUpdating && statusRef.current === 'LOADING') {
      statusRef.current = 'SUCCESS'
      setStatus('SUCCESS')
    }
  }, [isUpdating, status])

  useEffect(() => {
    if (!status || status === 'LOADING') return
    cancelAllTimers()
    st(() => {
      statusRef.current = null
      setStatus(null)
    }, 1800)
  }, [cancelAllTimers, st, status])

  return (
    <MenuItem
      loading={status === 'LOADING'}
      success={status === 'SUCCESS'}
      error={status === 'ERROR'}
      onClick={handleClick}
    >
      <BroomIcon />
      <div
        className={classNames(styles.loading_button__text, {
          [styles.loading]: status === 'LOADING',
          [styles.success]: status === 'SUCCESS',
        })}
      >
        <div className={styles.loading_button__text_inner}>
          <div className={styles.loading_button__message}>Clear cache</div>
          <div className={styles.loading_button__message} aria-live="assertive">
            {status === 'LOADING' && 'Clearing...'}
          </div>
          <div className={styles.loading_button__message} aria-live="assertive">
            {status === 'SUCCESS' && 'Cache Cleared'}
          </div>
        </div>
      </div>
    </MenuItem>
  )
}

ClearCacheButton.propTypes = {
  feedId: PropTypes.string,
}
