import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { updateInstagramFeed } from 'Fire/firebaseActions'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import useTimers from 'Hooks/useTimers'
import useHotkey from 'Hooks/useHotkey'
import ReauthPopup from 'Components/ReauthPopup'
import PopupMenu from 'Components/PopupMenu'
import ControlledInput from 'Components/ControlledInput'
import FeedMenu from 'Components/FeedMenu'
import HelpTooltip from './HelpTooltip'
import FeedIcon from 'Images/icons/feed-outline.svg'
import APIcon from 'Images/icons/json.svg'
import KlaviyoIcon from 'Images/klaviyo-outline.svg'
import styles from './FeedListItem.module.scss'

export default function FeedListItem({
  feed,
  reauth = false,
  reasonForReauth = null,
}) {
  const { st } = useTimers()
  const { setFeedTitle } = useSlice('setFeedTitle')
  const { type, subtype } = feed
  const [showReauthPopup, setShowReauthPopup] = useState(false)
  const [pausedButtonIsLoading, setPausedButtonIsLoading] = useState(false)
  const [editingTitle, setEditingTitle] = useState(false)
  const feedTitleRef = useRef(feed.title)
  const titleInputRef = useRef()
  const windowSize = useWindowSize()
  const [forceCloseMenu, setForceCloseMenu] = useState(false)

  const isActive = !reauth && feed.status === 'active'

  async function changeTitle(val) {
    setEditingTitle(false)
    if (val === feedTitleRef.current) return
    feedTitleRef.current = val

    try {
      setFeedTitle(feed.id, val)
      await updateInstagramFeed(feed.id, { title: val })
    } catch (error) {
      console.log(error)
    }
  }

  useHotkey(
    'Enter',
    () => changeTitle(titleInputRef.current?.value || feedTitleRef.current),
    editingTitle,
  )

  useEffect(() => {
    if (!feed.isUpdating && pausedButtonIsLoading) {
      setPausedButtonIsLoading(false)
    }
  }, [feed])

  function editTitle() {
    setEditingTitle(true)
    setForceCloseMenu(true)
    st(() => titleInputRef.current?.focus(), 50)
  }

  let typeIcon =
    type === 'WIDGET' ? (
      <>
        <FeedIcon /> Widget
      </>
    ) : (
      <>
        <APIcon /> JSON
      </>
    )

  if (subtype === 'KLAVIYO') {
    typeIcon = (
      <>
        <KlaviyoIcon /> Klaviyo{' '}
      </>
    )
  }

  let reauthMessage = (
    <>
      <p>
        <b>Disconnected</b>
      </p>
      <p>Reauthorize the source associated with this feed to reconnect.</p>
    </>
  )

  switch (reasonForReauth) {
    case 'PASSWORD_CHANGE':
      reauthMessage = (
        <>
          <b>Disconnected</b>
          <p>
            The password for this feed&apos;s source was changed or the access
            token was invalidated for security reasons.
          </p>
        </>
      )
      break

    case 'PRIVATE':
      reauthMessage = (
        <>
          <p>
            <b>Disconnected</b>
          </p>
          <p>This Instagram account was set to private. </p>
        </>
      )

    case 'LEGACY':
      reauthMessage = (
        <>
          <p>
            <b>Disconnected</b>
          </p>
          <p>
            The source for this feed is using the legacy Instagram API and needs
            to be reauthorized.{' '}
            <a
              href="https://behold.so/updates/basic-display-api-deprecated/"
              target="_blank"
            >
              Learn more.
            </a>
          </p>
          <p>
            <button onClick={() => setShowReauthPopup(true)}>
              Reauthorize
            </button>
          </p>
        </>
      )
      break
  }

  return (
    <div className={classNames(styles.container)}>
      <h3 className={styles.title} id={`title_${feed.id}`}>
        {editingTitle ? (
          <ControlledInput
            ref={titleInputRef}
            className={styles.title__input}
            value={feed.title}
            onChange={changeTitle}
            updateOnEnter={true}
            updateOn="blur"
            unstyled
          />
        ) : (
          <div className={styles.title__inner}>{feed.title}</div>
        )}
      </h3>
      <div className={styles.username}>
        <div className={styles.username__inner}>{feed.username}</div>
      </div>
      <div className={styles.type}>{typeIcon}</div>
      <div className={styles.usage}>
        {feed?.usage ? feed.usage.toLocaleString() : 0}
      </div>
      <HelpTooltip
        showIcon={false}
        width={isActive ? 70 : 250}
        triggerText={
          <div
            className={classNames(styles.status, { [styles.active]: isActive })}
          ></div>
        }
        className={styles.status_container}
      >
        <div
          className={classNames(styles.status_popup, {
            [styles.small]: isActive,
          })}
        >
          {isActive ? 'Active' : reauthMessage}
        </div>
      </HelpTooltip>
      <div className={styles.menu}>
        <PopupMenu
          forceClose={forceCloseMenu}
          onClose={() => setForceCloseMenu(false)}
          containerId={windowSize.width > 600 ? 'content' : 'root'}
          yAlign="bottom"
          offset={5}
          width={170}
        >
          <FeedMenu feedId={feed.id} editTitleHandler={editTitle} />
        </PopupMenu>
      </div>
      {showReauthPopup && (
        <ReauthPopup
          sourceId={feed.sourceId}
          onRequestClose={() => setShowReauthPopup(false)}
        />
      )}
    </div>
  )
}

FeedListItem.propTypes = {
  feed: PropTypes.object,
  reauth: PropTypes.bool,
  reasonForReauth: PropTypes.string,
  first: PropTypes.bool,
  last: PropTypes.bool,
}
