import useFeatures from 'Hooks/useFeatures'
import NavLink from './NavLink'
import styles from './SettingsNav.module.scss'

export default function SettingsNav() {
  const features = useFeatures()
  return features.has('api') ? (
    <nav className={styles.container}>
      <NavLink
        to="/account"
        currentClass={styles.current}
        className={styles.link}
      >
        Account
      </NavLink>
      <NavLink
        to="/developer-settings"
        currentClass={styles.current}
        className={styles.link}
      >
        Developers
      </NavLink>
    </nav>
  ) : null
}
