import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { forceLayout } from 'Helpers/utils'
import CheckIcon from 'Images/icons/check.svg'
import styles from './Toast.module.scss'

export default function Toast({ isActive, children, minTimeVisible = 2000 }) {
  const [isVisible, setIsVisible] = useState(false)
  const [status, setStatus] = useState('LOADING')
  const [contents, setContents] = useState(children)
  const [width, setWidth] = useState(0)
  const containerRef = useRef()
  const childrenRef = useRef()
  const getWidthTo = useRef()
  const openRaf = useRef()
  const startTime = useRef()
  const toRef = useRef()

  function updateWidth() {
    clearTimeout(getWidthTo.current)
    forceLayout()

    getWidthTo.current = setTimeout(() => {
      const containerEl = containerRef.current
      const childEl = childrenRef.current
      if (!containerEl || !childEl) return

      const computed = window.getComputedStyle(containerEl)
      const paddingLeft = parseInt(computed['padding-left'].replace('px', ''))
      const paddingRight = parseInt(computed['padding-right'].replace('px', ''))
      const gap = parseInt(computed['gap'].replace('px', ''))
      const loaderWidth = 22

      setWidth(
        childEl.scrollWidth + paddingLeft + paddingRight + gap + loaderWidth,
      )
    }, 50)
  }

  useEffect(() => {
    updateWidth()

    if (isActive && !isVisible) {
      clearTimeout(toRef.current)
      clearTimeout(openRaf.current)
      setContents(children)
      startTime.current = Date.now()
      openRaf.current = setTimeout(() => {
        setIsVisible(true)
      }, 100)
      updateWidth()
    }
    if (!isActive && isVisible && status == 'LOADING') {
      clearTimeout(toRef.current)
      clearTimeout(openRaf.current)
      const elapsedTime = Date.now() - startTime.current
      const minTimeRemaining = Math.max(minTimeVisible - elapsedTime, 0)

      toRef.current = setTimeout(() => {
        setStatus('SUCCESS')

        toRef.current = setTimeout(
          () => {
            setIsVisible(false)

            toRef.current = setTimeout(() => {
              setStatus('LOADING')
            }, 350)
          },
          1000 + minTimeRemaining * 0.4,
        )
      }, minTimeRemaining * 0.6)
    }
    return () => {
      clearTimeout(toRef.current)
    }
  }, [isActive])

  useEffect(() => {
    if (!children) return
    clearTimeout(toRef.current)
    setContents(children)
    setStatus('LOADING')
    startTime.current = Date.now()
    updateWidth()
  }, [children])

  const containerWidth = status === 'SUCCESS' ? '46px' : `${width}px`

  return (
    <div
      ref={containerRef}
      style={{ width: containerWidth }}
      aria-hidden="true"
      className={classNames(styles.container, {
        [styles.visible]: isVisible,
        [styles.loading]: status === 'LOADING',
        [styles.success]: status === 'SUCCESS',
      })}
    >
      <div className={styles.icon}>
        <l-quantum
          className={styles.loader}
          size="22"
          color="#3fe082"
          speed="1.5"
          stroke="2"
        ></l-quantum>
        <CheckIcon className={styles.icon} />
      </div>
      <div ref={childrenRef} className={styles.children}>
        {contents}
      </div>
    </div>
  )
}
