import { useState } from 'preact/hooks'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { auth } from 'Fire/firebaseInit'
import { isPopulated, getISOString, multiSort } from 'Helpers/utils'
import useFeatures from 'Hooks/useFeatures'
import { useSlice } from 'State'
import Layout from 'Components/Layout'
import FeedCard from 'Components/FeedCard'
import FeedListItem from 'Components/FeedListItem'
import PageHeader from 'Components/PageHeader'
import TransitionWrapper from 'Components/TransitionWrapper'
import Pagination from 'Components/Pagination'
import PlusIcon from 'Images/icons/plus.svg'
import ConnectIcon from 'Images/icons/connect.svg'
import CaretDown from 'Images/icons/caret-down.svg'
import styles from './Feeds.module.scss'

export default function Feeds() {
  const appState = useSlice(
    'feeds',
    'user',
    'authorizedAccounts',
    'plans',
    'newFeedIsLoading',
    'addNotification',
  )
  const feeds = appState?.feeds
  const user = appState?.user
  const sources = appState?.authorizedAccounts
  const features = useFeatures()
  const [currentPage, setCurrentPage] = useState(1)
  let pageSize = 12

  if (Object.values(feeds).length > 240) {
    pageSize = 25
  }

  if (Object.values(feeds).length > 500) {
    pageSize = 50
  }

  /*
   *   Active Plan
   */
  const planId = user?.plan
  const activePlan = planId ? appState?.plans?.[planId] : null

  const [sortBy, setSortBy] = useState('title')
  const [sortOrder, setSortOrder] = useState('asc')

  Object.values(feeds).forEach((feed) => {
    const currMonthIso = getISOString(new Date(), 'month')
    const usageObj = feed?.monthlyViews?.[currMonthIso] ?? {}
    const usage = Object.values(usageObj).reduce((acc, curr) => {
      return acc + curr
    }, 0)
    feed.usage = usage
  })

  function setSort(by, order) {
    if (order === 'toggle') {
      order = sortOrder === 'desc' ? 'asc' : 'desc'
    }
    if (by === sortBy) {
      setSortOrder(order)
    }
    setSortBy(by)
  }

  function showVerifyPrompt() {
    appState.addNotification(
      {
        text: <>A verified email is required for that action</>,
        duration: 5,
      },
      'VERIFIED_EMAIL_REQUIRED',
    )
  }

  /*
   *   Loading el
   */
  const loadingEl = (
    <div className={styles.loading_feed}>
      <l-squircle
        stroke="3"
        size={30}
        color="var(--color-text-light)"
      ></l-squircle>
    </div>
  )

  const showListView = features.has('api')

  let sortedFeeds = Object.values(feeds)
    .filter((f) => !!f.created && sources?.[f.sourceId])
    .map((feed) => {
      feed.status = 'active'

      if (!feed.sourceIsActive || feed.sourceType.includes('Legacy')) {
        feed.status = 'inactive'
      }

      return feed
    })

  if (showListView) {
    switch (sortBy) {
      case 'title':
        sortedFeeds = multiSort(
          sortedFeeds,
          ['title', 'username', 'type', 'status', 'usage'],
          sortOrder,
        )
        break
      case 'username':
        sortedFeeds = multiSort(
          sortedFeeds,
          ['username', 'title', 'type', 'status', 'usage'],
          sortOrder,
        )
        break
      case 'output':
        sortedFeeds = multiSort(
          sortedFeeds,
          ['type', 'title', 'username', 'status', 'usage'],
          sortOrder,
        )
        break
      case 'status':
        sortedFeeds = multiSort(
          sortedFeeds,
          ['status', 'title', 'username', 'type', 'usage'],
          sortOrder,
        )
        break
      case 'usage':
        sortedFeeds = multiSort(
          sortedFeeds,
          ['usage', 'title', 'username', 'type', 'status'],
          sortOrder,
        )
        break
    }
  } else {
    sortedFeeds = sortedFeeds.sort((a, b) => {
      return a.created.seconds
        .toString()
        .localeCompare(b.created.seconds.toString())
    })
  }

  /*
   *   Feed els
   */
  const feedEls = isPopulated(sortedFeeds)
    ? sortedFeeds
        .filter((_f, i) => {
          if (!showListView) return true
          return (
            i + 1 >= currentPage * pageSize - pageSize + 1 &&
            i + 1 <= currentPage * pageSize
          )
        })
        .map((f, i, arr) => {
          const sourceId = f.sourceId
          const source = sources[sourceId]
          return showListView ? (
            <FeedListItem
              key={f.id}
              feed={f}
              plan={activePlan}
              reauth={source?.reauthorize || source.type.includes('Legacy')}
              reasonForReauth={
                source.type.includes('Legacy')
                  ? 'LEGACY'
                  : source?.reasonForReauth
              }
            />
          ) : (
            <FeedCard
              key={f.id}
              feed={f}
              plan={activePlan}
              reauth={source?.reauthorize || source.type.includes('Legacy')}
              reasonForReauth={
                source.type.includes('Legacy')
                  ? 'LEGACY'
                  : source?.reasonForReauth
              }
              first={i === 0}
              last={i >= arr.length - 1}
            />
          )
        })
    : null

  const addFeedEl = auth.currentUser.emailVerified ? (
    <Link className={classnames(styles.add_feed)} to="/add-feed">
      <div className={styles.add_feed__inner}>
        <PlusIcon /> Add feed
      </div>
    </Link>
  ) : (
    <button className={classnames(styles.add_feed)} onClick={showVerifyPrompt}>
      <div className={styles.add_feed__inner}>
        <PlusIcon /> Add feed
      </div>
    </button>
  )

  const welcomeMessageEl = (
    <Link to="/add-source" className={styles.empty_message}>
      <div className={styles.empty_message__inner}>
        <ConnectIcon />
        <div>
          Welcome! To get started let&apos;s <u>connect a source</u>
        </div>
      </div>
    </Link>
  )

  const addFeedMessageEl = (
    <Link
      to="/add-feed"
      className={classnames(styles.empty_message, styles.empty_message__feeds)}
    >
      <div className={styles.empty_message__inner}>
        Congratulations! You&apos;ve successfully connected a source. Now
        let&apos;s{' '}
        <TransitionWrapper scale className={styles.empty_message__button}>
          <div className={styles.empty_message__button_inner}>Add a feed</div>
        </TransitionWrapper>
      </div>
    </Link>
  )

  const feedsWrapperEl = showListView ? (
    <>
      <div className={styles.feeds_list_labels}>
        <button
          className={styles.feeds_list_label}
          onClick={() => setSort('title', 'toggle')}
        >
          Title{' '}
          <div
            className={classnames(styles.feeds_list_label__caret, {
              [styles.current]: sortBy === 'title',
              [styles.sort_asc]: sortOrder === 'asc',
            })}
          >
            <CaretDown />
          </div>
        </button>
        <button
          className={styles.feeds_list_label}
          onClick={() => setSort('username', 'toggle')}
        >
          Username
          <div
            className={classnames(styles.feeds_list_label__caret, {
              [styles.current]: sortBy === 'username',
              [styles.sort_asc]: sortOrder === 'asc',
            })}
          >
            <CaretDown />
          </div>
        </button>
        <button
          className={classnames(
            styles.feeds_list_label,
            styles.feeds_list_label__output,
          )}
          onClick={() => setSort('output', 'toggle')}
        >
          Output
          <div
            className={classnames(styles.feeds_list_label__caret, {
              [styles.current]: sortBy === 'output',
              [styles.sort_asc]: sortOrder === 'asc',
            })}
          >
            <CaretDown />
          </div>
        </button>
        <button
          className={classnames(
            styles.feeds_list_label,
            styles.feeds_list_label__centered,
            styles.feeds_list_label__source_views,
          )}
          onClick={() => {
            const order = sortBy === 'usage' ? 'toggle' : 'desc'
            setSort('usage', order)
          }}
        >
          {new Date().toLocaleString('default', {
            month: 'short',
            timeZone: 'UTC',
          })}{' '}
          Views
          <div
            className={classnames(styles.feeds_list_label__caret, {
              [styles.current]: sortBy === 'usage',
              [styles.sort_asc]: sortOrder === 'asc',
            })}
          >
            <CaretDown />
          </div>
        </button>
        <button
          className={classnames(
            styles.feeds_list_label,
            styles.feeds_list_label__centered,
            styles.feeds_list_label__status,
          )}
          onClick={() => setSort('status', 'toggle')}
        >
          Status
          <div
            className={classnames(styles.feeds_list_label__caret, {
              [styles.current]: sortBy === 'status',
              [styles.sort_asc]: sortOrder === 'asc',
            })}
          >
            <CaretDown />
          </div>
        </button>
      </div>
      <div className={styles.feeds_list}>{feedEls}</div>
      <Pagination
        curr={currentPage}
        items={sortedFeeds}
        pageSize={pageSize}
        onChange={setCurrentPage}
      />
    </>
  ) : (
    <TransitionWrapper className={styles.feeds_grid}>
      {feedEls}
      {appState.newFeedIsLoading && loadingEl}
      {!appState.newFeedIsLoading && isPopulated(sources) && addFeedEl}
    </TransitionWrapper>
  )

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Feeds | Behold">
      <main className={classnames(styles.container)}>
        <PageHeader
          title="Feeds"
          buttonText={
            <>
              <PlusIcon /> Add feed
            </>
          }
          buttonTo={auth.currentUser.emailVerified ? '/add-feed' : null}
          buttonAction={
            auth.currentUser.emailVerified ? null : showVerifyPrompt
          }
        />

        {isPopulated(sources) && isPopulated(feeds) && feedsWrapperEl}

        {(!sources || !feeds) && (
          <div className={classnames(styles.feeds_loading)}>
            <div className={styles.feeds_loading__loader} />
            <div className={styles.feeds_loading__loader} />
          </div>
        )}

        {!appState.newFeedIsLoading && isPopulated(sources, false)
          ? welcomeMessageEl
          : null}

        <TransitionWrapper
          show={
            !appState.newFeedIsLoading &&
            isPopulated(sources) &&
            isPopulated(feeds, false)
          }
          transform="scale(.97)"
        >
          {addFeedMessageEl}
        </TransitionWrapper>
      </main>
    </Layout>
  )
}
