import Layout from 'Components/Layout'
import TransitionWrapper from 'Components/TransitionWrapper'
import PageHeader from 'Components/PageHeader'
import styles from './DeveloperSettingsNotSupported.module.scss'

export default function DeveloperSettingsNotSupported() {
  return (
    <Layout metaTitle="Developer Settings | Behold">
      <main className={styles.container}>
        <PageHeader title="Developer settings" />

        <TransitionWrapper>
          <div className={styles.inner}>
            <p>To access the Admin API you must be on the Platform plan.</p>
            <p>
              <a href="/choose-plan/platform-month/confirm">Upgrade now</a> or
              contact <a href="mailto:sales@behold.so">sales@behold.so</a> for
              more information.
            </p>
          </div>
        </TransitionWrapper>
      </main>
    </Layout>
  )
}
